import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
    {
        exact: true,
        path: "/",
        layout: LoginLayout,
        component: lazy(() => import("src/views/pages/Home/auth/Login/index")),
    },
    {
        exact: true,
        path: "/forget-password",
        layout: LoginLayout,
        component: lazy(() =>
            import("src/views/pages/Home/auth/forget-password/index")
        ),
    },
    {
        exact: true,
        path: "/Verify-Otp",
        layout: LoginLayout,
        component: lazy(() =>
            import("src/views/pages/Home/auth/Verify-Otp/VerifyOtp")
        ),
    },
    {
        exact: true,
        path: "/reset-password",
        layout: LoginLayout,
        component: lazy(() =>
            import("src/views/pages/Home/auth/reset-password/index")
        ),
    },
    {
        exact: true,
        path: "/access-Management",
        layout: DashboardLayout,
        component: lazy(() =>
            import(
                "src/views/pages/Dashboard/RfidAccessManagement/RfidAccessManagement"
            )
        ),
    },
    {
        exact: true,
        path: "/bloackchain-explorer",
        layout: DashboardLayout,
        component: lazy(() =>
            import(
                "src/views/pages/Dashboard/RfidAccessManagement/BloackchainExplorerAccess"
            )
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/access-Management/add-device",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/RfidAccessManagement/AddNewRFID")
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/access-Management/add-employee",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/RfidAccessManagement/AddNePerson")
        ),
    },
    {
        exact: true,
        guard: true,
        path: "/access-Management/edit-device",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/RfidAccessManagement/EditDevice")
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/access-Management/edit-employee",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/RfidAccessManagement/EditPerson")
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/user-managment",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/UerManagement/usermanagement")
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/user-managment/user-view",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/UerManagement/ViewUser")
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/user-managment/edit-user",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/UerManagement/EditUser")
        ),
    },
    {
        guard: true,
        exact: true,
        path: "/user-managment/add-user",
        layout: DashboardLayout,
        component: lazy(() =>
            import("src/views/pages/Dashboard/UerManagement/AddUser")
        ),
    },
    {
        exact: true,
        path: "/404",
        layout: DashboardLayout,
        component: lazy(() => import("src/views/errors/NotFound")),
    },
    {
        component: () => <Redirect to="/404" />,
    },
];
