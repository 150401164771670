/* eslint-disable no-use-before-define */
import React, { useContext, useEffect, useState } from "react";
import { useLocation, matchPath, useHistory } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import PropTypes from "prop-types";

import {
    Box,
    Drawer,
    Hidden,
    List,
    ListSubheader,
    makeStyles,
    Avatar,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Typography,
    Slide,
} from "@material-ui/core";
import NavItem from "./NavItem";

import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { MdDashboard } from "react-icons/md";
import { FaUsers, FaUserAlt, FaQrcode } from "react-icons/fa";

import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { AuthContext } from "src/context/Auth";

const sections = [
    {
        items: [
            {
                title: "Access Management",
                icon: MdDashboard,
                href: "/access-Management",
            },
            {
                title: "User Management",
                icon: FaUsers,
                href: "/userManagment",
            },
        ],
    },
];
const data = [
    {
        items: [
            {
                title: "Access Management",
                icon: MdDashboard,
                href: "/access-Management",
            },
        ],
    },
];

function renderNavItems({ items, pathname, depth = 0 }) {
    return (
        <List disablePadding key={pathname}>
            {items.reduce(
                (acc, item) =>
                    reduceChildRoutes({ acc, item, pathname, depth }),
                []
            )}
        </List>
    );
}

function reduceChildRoutes({ acc, pathname, item, depth }) {
    const key = item.title + depth;

    if (item.items) {
        const open = matchPath(pathname, {
            path: item.href,
            exact: false,
        });

        acc.push(
            <NavItem
                depth={depth}
                icon={item.icon}
                info={item.info}
                key={key}
                open={Boolean(open)}
                title={item.title}
            >
                {console.log(key)}

                {renderNavItems({
                    depth: depth + 1,
                    pathname,
                    items: item.items,
                })}
            </NavItem>
        );
    } else {
        acc.push(
            <NavItem
                depth={depth}
                href={item.href}
                icon={item.icon}
                info={item.info}
                key={key}
                title={item.title}
            />
        );
    }
    return acc;
}

const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
        width: 256,
        background: "#fff",
        backdropFilter: "blur(44px)",
    },
    desktopDrawer: {
        width: 256,
        top: 0,
        height: "100%",
        background: "#fff",
        backdropFilter: "blur(44px)",
        boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
    socialIcon: {
        cursor: "pointer",
        marginRight: 5,
    },
    logoicon: {
        display: "flex",
        marginTop: "10px",
        alignItems: "center",
        marginLeft: "30px",
    },

    large: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
    logoutButton: {
        textAlign: "left",
        display: "flex",
        justifyContent: "flex-start",
        color: "#000",

        "&:hover": {
            color: "#fff",
            background: "rgb(60,141,188) !important",
            borderRadius: "none !important",
        },
    },
    itemLeaf: {
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
        marginTop: "2px",
    },
    buttonLeaf: {
        color: "#f5f7fac9",
        padding: "11px 8px",
        justifyContent: "flex-start",
        textTransform: "none",
        letterSpacing: 0,
        borderLeft: "solid 8px transparent",
        borderRadius: 0,
        fontSize: "13px",
        borderLeft: "3px solid transparent",
        "&:hover": {
            background: "rgb(90 134 255)",
            color: "#fff",
        },

        "&.depth-0": {
            "& $title": {
                fontWeight: theme.typography.fontWeightMedium,
            },
        },
    },
    active: {
        borderLeft: "3px solid #fff",

        fontWeight: theme.typography.fontWeightRegular,
        background: "#E41D29",
        "& $title": {
            fontWeight: theme.typography.fontWeightMedium,
        },
        "& $icon": {
            color: "00e0b0",
        },
    },
}));

const NavBar = ({ onMobileClose, openMobile }) => {
    const usertype = window.sessionStorage.getItem("userType");
    const auth = useContext(AuthContext);
    const classes = useStyles();
    const location = useLocation();
    const history = useHistory();
    const [colorbg, setColor] = useState();
    const [selectedIndex, setSelectedIndex] = React.useState(1);

    const [open1, setOpen1] = React.useState(false);
    const logouthandler = () => {
        auth.userLogIn(false, null);
        window.sessionStorage.removeItem("userType");
        window.sessionStorage.removeItem("token");
        window.sessionStorage.removeItem("email");
        history.push("/");
    };

    const handleClick = (event, index) => {
        setOpen1(!open1);
        setSelectedIndex(index);
    };
    const [isLogout, setIsLogout] = useState(false);
    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
    }, []);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
            className="example"
        >
            <PerfectScrollbar options={{ suppressScrollX: true }}>
                <Box
                    mt={2}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        cursor: "pointer",
                    }}
                >
                    <Avatar
                        onClick={() => history.push("/settings")}
                        className={classes.large}
                        src="images/mobiloitte.png"
                    />
                </Box>
                <Box textAlign="center" pt={1}>
                    <Typography variant="h6" style={{ color: "#000" }}>
                        Admin
                    </Typography>
                </Box>
                {/* {
          usertype === 'Admin' ? 
        } */}
                <Box pt={1}>
                    {usertype === "Admin"
                        ? sections.map((section, i) => (
                              <>
                                  <List
                                      key={`menu${i}`}
                                      subheader={
                                          <ListSubheader
                                              disableGutters
                                              disableSticky
                                          >
                                              {section.subheader}
                                          </ListSubheader>
                                      }
                                  >
                                      {renderNavItems({
                                          items: section.items,
                                          pathname: location.pathname,
                                      })}
                                  </List>
                              </>
                          ))
                        : data.map((section, i) => (
                              <>
                                  <List
                                      key={`menu${i}`}
                                      subheader={
                                          <ListSubheader
                                              disableGutters
                                              disableSticky
                                          >
                                              {section.subheader}
                                          </ListSubheader>
                                      }
                                  >
                                      {renderNavItems({
                                          items: section.items,
                                          pathname: location.pathname,
                                      })}
                                  </List>
                              </>
                          ))}
                </Box>
                <Button
                    fullWidth
                    onClick={() => setIsLogout(true)}
                    className={classes.logoutButton}
                >
                    <ExitToAppIcon style={{ marginLeft: "13px" }} />
                    &nbsp; &nbsp; Logout
                </Button>
            </PerfectScrollbar>

            {isLogout && (
                <Dialog
                    open={isLogout}
                    keepMounted
                    onClose={() => setIsLogout(false)}
                >
                    <DialogContent>
                        <Box className={classes.dialougTitle}>
                            <Typography
                                variants="h2"
                                style={{ fontSize: "20px", color: "#000" }}
                            >
                                Are you sure want to logout?
                            </Typography>
                        </Box>
                    </DialogContent>
                    <DialogActions
                        style={{
                            alignContent: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box>
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={() => setIsLogout(false)}
                            >
                                No
                            </Button>
                            &nbsp;
                            <Button
                                color="primary"
                                variant="contained"
                                onClick={logouthandler}
                            >
                                Yes
                            </Button>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
};

export default NavBar;
