export default {
    h1: {
        fontWeight: 700,
        fontSize: 35,

        fontFamily: "'Inter',normal",
    },
    h2: {
        fontWeight: 700,
        fontSize: 26,
        fontFamily: "'Lato',normal",
    },
    h3: {
        fontWeight: 700,
        fontSize: 24,

        fontFamily: "'Lato',normal",
    },
    h4: {
        fontWeight: 400,
        fontSize: 20,
        fontFamily: "'Lato',normal",
    },
    h5: {
        fontWeight: 600,
        fontSize: 14,
        fontFamily: "'Lato',normal",
    },
    h6: {
        fontWeight: 400,
        fontSize: 16,
        fontFamily: "'Lato',normal",
    },

    body1: {
        fontSize: 16,
        fontFamily: "'Lato',normal",
        fontWeight: 400,
    },
    body2: {
        fontSize: 14,
        fontFamily: "'Lato',normal",
        fontWeight: 500,
        lineHeight: "17px",
    },
};
