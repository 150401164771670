import { ContactlessOutlined } from "@material-ui/icons";
import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
import { AuthContext } from "src/context/Auth";

export default function AuthGuard(props) {
    const { children } = props;
    const auth = useContext(AuthContext);
    const isAdmin =
        window.sessionStorage.getItem("userType") === "Admin" ? true : false;
    if (!isAdmin) {
        window.sessionStorage.removeItem("token");
        return <Redirect to="/" />;
    }

    return <>{children}</>;
}
