import React, { createContext, useState } from "react";
import axios from "axios";
import { TbGitPullRequestClosed } from "react-icons/tb";

export const AuthContext = createContext();

const setSession = (accessToken) => {
  if (accessToken) {
    sessionStorage.setItem("token", accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    sessionStorage.removeItem("token");
    delete axios.defaults.headers.common.Authorization;
  }
};

function checkLogin() {
  return window.sessionStorage.getItem("token") ? true : false;
}


export default function AuthProvider(props) {
  const [isLogin, setIsLogin] = useState(checkLogin());
  const[isUser,setISUser] = useState('');
  const[tittle,setTittle] = useState("Access Management");
  const [dataDetails, setDataDetails] = useState();
  const [userData] = useState({});

  let data = {
    userLoggedIn: isLogin,
    userData,
    tittle,
    setTittle,
    dataDetails,
    setIsLogin: (data) => setIsLogin(data),
    checkLogin: (data) => checkLogin(data),
    userLogIn: (type, data) => {
      setSession(data);
      setIsLogin(type);
    },
  };

  return (
    <AuthContext.Provider value={data}>{props.children}</AuthContext.Provider>
  );
}
